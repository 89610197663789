.mapWrapper {
  @apply overflow-hidden;
}

.mapWrapper .pin {
  @apply transform-none;
}

.mapWrapper .pinWrapper .pin {
  transform: translateY(20px) scale(0.5);
  @apply opacity-0;
}

.mapWrapper .pinWrapper:nth-of-type(1) .pin .circle {
  //transform: rotate(360deg);
  transition: all 3s ease;
}

.mapWrapper.animated .pinWrapper:nth-of-type(1) .pin .circle {
  @apply transform-none;
}

.mapWrapper .pinWrapper:nth-of-type(1) .pin .tick {
  transform: translate(-50%, -50%) scale(0);
  transition: all 1s ease 1s;
}

.mapWrapper.animated .pinWrapper:nth-of-type(1) .pin .tick {
  transform: translate(-50%, -50%);
}

.mapWrapper.animated .pinWrapper:nth-of-type(1) .pin {
  @apply opacity-100;
  @apply transform-none;
  transition: transform 1s ease .5s, opacity .6s ease .5s;
}

.mapWrapper.animated .pinWrapper:nth-of-type(2) .pin {
  @apply opacity-100;
  @apply transform-none;
  transition: transform 1s ease 1s, opacity .6s ease 1s;
}

.mapWrapper.animated .pinWrapper:nth-of-type(3) .pin {
  @apply opacity-100;
  @apply transform-none;
  transition: transform 1s ease 1.5s, opacity .6s ease 1.5s;
}

.mapWrapper.animated .pinWrapper:nth-of-type(4) .pin {
  @apply opacity-100;
  @apply transform-none;
  transition: transform 1s ease 2s, opacity .6s ease 2s;
}

@media (min-width: 648px) {
  .mapWrapper {
    @apply overflow-visible;
  }
}